import React from 'react';
import { IIconProps } from '.';
import IconType from './IconType';
import StatusSuccess from './svg/StatusSuccess';
import StatusFailure from './svg/StatusFailure';
import StatusWaiting from './svg/StatusWaiting';
import StatusWarning from './svg/StatusWarning';
import TagAddress from './svg/TagAddress';
import TagStore from './svg/TagStore';
import TagNetwork from './svg/TagNetwork';
import TagVoucher from './svg/TagVoucher';
import TagItem from './svg/TagItem';
import Alipay from './svg/Alipay';
import WeChatPay from './svg/WeChatPay';
import ApplePay from './svg/ApplePay';
import CreditCard from './svg/CreditCard';
import OePay from './svg/OePay';
import AeCard from './svg/AeCard';
import GooglePay from './svg/GooglePay';
import JcbCard from './svg/JcbCard';
import MasterCard from './svg/MasterCard';
import TapgoPay from './svg/TapgoPay';
import UnionPay from './svg/UnionPay';
import VisaCard from './svg/VisaCard';
import Cash from './svg/Cash';
import GiftCard from './svg/GiftCard';
import Loyalty from './svg/Loyalty';
import UpOutlined from './svg/UpOutlined';
import DownOutlined from './svg/DownOutlined';
import LeftOutlined from './svg/LeftOutlined';
import RightOutlined from './svg/RightOutlined';
import CloseOutlined from './svg/CloseOutlined';
import QuestionCircleOutlined from './svg/QuestionCircleOutlined';
import SelectedOutlined from './svg/SelectedOutlined';
import UnSelectedOutlined from './svg/UnSelectedOutlined';
import MinusOutlined from './svg/MinusOutlined';
import MinusCircleOutlined from './svg/MinusCircleOutlined';
import PlusOutlined from './svg/PlusOutlined';
import PlusCircleOutlined from './svg/PlusCircleOutlined';
import TimeOutlined from './svg/TimeOutlined';
import StoreOutlined from './svg/StoreOutlined';
import StarOutlined from './svg/StarOutlined';
import RecycleBinOutlined from './svg/RecycleBinOutlined';
import CheckedOutlined from './svg/CheckedOutlined';
import SearchOutlined from './svg/SearchOutlined';
import PhoneOutlined from './svg/PhoneOutlined';
import EmailOutlined from './svg/EmailOutlined';
import EyeOpenOutlined from './svg/EyeOpenOutlined';
import EyeClosedOutlined from './svg/EyeClosedOutlined';
import PickUpOutlined from './svg/PickUpOutlined';
import DeliveryOutlined from './svg/DeliveryOutlined';
import BookOutlined from './svg/BookOutlined';
import SettingOutlined from './svg/SettingOutlined';
import AddressOutlined from './svg/AddressOutlined';
import DefaultAvatarOutlined from './svg/DefaultAvatarOutlined';
import EditOutlined from './svg/EditOutlined';
import CameraOutlined from './svg/CameraOutlined';
import TimeSolidOutlined from './svg/TimeSolidOutlined'
import NavAccountOutlined from './svg/NavAccountOutlined';
import NavOrdersOutlined from './svg/NavOrdersOutlined';
import MapOutlined from './svg/MapOutlined';
import ShoppingCartOutlined from './svg/ShoppingCartOutlined';
import GuestOutlined from './svg/GuestOutlined';
import AlreadyFavoriteOutlined from './svg/AlreadyFavoriteOutlined';
import NotFavoriteOutlined from './svg/NotFavoriteOutlined';
import ScanOutlined from './svg/ScanOutlined';
import BookingOutlined from './svg/BookingOutlined';
import QueueingOutlined from './svg/QueueingOutlined';
import FacebookOutlined from './svg/FacebookOutlined';
import AppleOutlined from './svg/AppleOutlined';
import WechatOutlined from './svg/WechatOutlined';
import TelPhoneOutlined from './svg/TelPhoneOutlined';
import Loading from './svg/Loading';
import NewsOutlined from './svg/NewsOutlined';
import AboutUsOutlined from './svg/AboutUsOutlined';
import LocationOutlined from './svg/LocationOutlined';
import QuickOutlined from './svg/QuickOutlined';
import VoucherOutlined from './svg/VoucherOutlined';
import BookingSetting from './svg/BookingSetting';
import QueueingSetting from './svg/QueueingSetting';
import FavoriteSetting from './svg/FavoriteSetting';
import PaymentSetting from './svg/PaymentSetting';
import BindoSetting from './svg/BindoSetting';
import BecomePartnerSetting from './svg/BecomePartnerSetting';
import HomeOutlined from './svg/HomeOutlined';
import MembersOutlined from './svg/MembersOutlined';
import PromotionsOutlined from './svg/PromotionsOutlined';
import RecordsOutlined from './svg/RecordsOutlined';
import ShoppingOutlined from './svg/ShoppingOutlined';
import StaffIDOutlined from './svg/StaffIDOutlined';
import QRCodeOutlined from './svg/QRCodeOutlined';
import MemberCardOutlined from './svg/MemberCardOutlined';
import EditAddressOutlined from './svg/EditAddressOutlined';
import MyIDOutlined from './svg/MyIDOutlined';
import MemberOutlined from './svg/MemberOutlined';
import MemberTagOutlined from './svg/MemberTagOutlined';
import MenusOutlined from './svg/MenusOutlined';
import PositionOutlined from './svg/PositionOutlined';
import GoogleLocaltionOutlined from './svg/GoogleLocaltionOutlined';
import FoodOrderingOutlined from './svg/FoodOrderingOutlined';
import RefreshOutlined from './svg/RefreshOutlined';
import ShareOutlined from './svg/ShareOutlined';
import DateOntlined from './svg/DateOntlined';
import BookingSettingBrand from './svg/BookingSettingBrand';
import QueueingSettingBrand from './svg/QueueingSettingBrand';
import ComingSoonOutlined from './svg/ComingSoonOutlined';
import DeliverooOutlined from './svg/DeliverooOutlined';
import FoodpandaOutlined from './svg/FoodpandaOutlined';
import StoreUrlOutlined from './svg/StoreUrlOutlined';
import CategoryOutlined from './svg/CategoryOutlined';
import ScreenOutlined from './svg/ScreenOutlined';
import StatusErrorOutlined from './svg/StatusErrorOutlined';
import LeftArrowOutlined from './svg/LeftArrowOutlined';
import ExpressOrderOutlined from './svg/ExpressOrderOutlined';
import WhatsAppOutlined from './svg/WhatsAppOutlined';
import AppleEmailOutlined from './svg/AppleEmailOutlined';
import GEmailOutlined from './svg/GEmailOutlined';
import CopyOutlined from './svg/CopyOutlined';
import PrivilegeOutlined from './svg/PrivilegeOutlined';
import UnSelectedSquareOutlined from './svg/UnSelectedSquareOutlined';
import CheckedSquareOutlined from './svg/CheckedSquareOutlined';
import AlipayHK from './svg/AlipayHK';
import NavLogo from './svg/NavLogo';
import ShoppingCart from './svg/ShoppingCart';
import DeferredInvoiceOutlined from './svg/DeferredInvoiceOutlined';
import JustLogo from './svg/JustLogo';
import Facebook from './svg/Facebook';
import Twitter from './svg/Twitter';
import Instagram from './svg/Instagram';
import LogoutOutlined from './svg/LogoutOutlined';
import Setting2Outlined from './svg/Setting2Outlined';
import DollarOutlined from './svg/DollarOutlined';
import PaymentOutlined from './svg/PaymentOutlined';
import RecordOutlined from './svg/RecordOutlined';
import GRNOutlined from './svg/GRNOutlined';
import RNOutlined from './svg/RNOutlined';

const Icon: React.FC<IIconProps> = props => {
  const { type, className = '' } = props;
  const iconProps = {
    ...props,
    className: `${className} ${type.toLocaleLowerCase()}`
  }
  return (
    <>
      { type === IconType.STATUS_SUCCESS && <StatusSuccess {...iconProps} /> }
      { type === IconType.STATUS_FAILURE && <StatusFailure {...iconProps} /> }
      { type === IconType.STATUS_WAITING && <StatusWaiting {...iconProps} /> }
      { type === IconType.STATUS_WARNING && <StatusWarning {...iconProps} /> }
      { type === IconType.TAG_ADDRESS && <TagAddress {...iconProps} /> }
      { type === IconType.TAG_STORE && <TagStore {...iconProps} /> }
      { type === IconType.TAG_NETWORK && <TagNetwork {...iconProps} /> }
      { type === IconType.TAG_VOUCHER && <TagVoucher {...iconProps} /> }
      { type === IconType.TAG_ITEM && <TagItem {...iconProps} /> }
      { type === IconType.ALIPAY && <Alipay {...iconProps} /> }
      { type === IconType.WECHAT_PAY && <WeChatPay {...iconProps} /> }
      { type === IconType.APPLE_PAY && <ApplePay {...iconProps} /> }
      { type === IconType.CREDIT_CARD && <CreditCard {...iconProps} /> }
      { type === IconType.OE_PAY && <OePay {...iconProps} /> }
      { type === IconType.AE_CARD && <AeCard {...iconProps} /> }
      { type === IconType.GOOGLE_PAY && <GooglePay {...iconProps} /> }
      { type === IconType.JCB_CARD && <JcbCard {...iconProps} /> }
      { type === IconType.MASTER_CARD && <MasterCard {...iconProps} /> }
      { type === IconType.TAPGO_PAY && <TapgoPay {...iconProps} /> }
      { type === IconType.UNION_PAY && <UnionPay {...iconProps} /> }
      { type === IconType.VISA_CARD && <VisaCard {...iconProps} /> }
      { type === IconType.CASH_ON_DELIVERY && <Cash {...iconProps} /> }
      { type === IconType.GIFT_CARD && <GiftCard {...iconProps} /> }
      { type === IconType.LOYALTY && <Loyalty {...iconProps} /> }
      { type === IconType.UP_OUTLINED && <UpOutlined {...iconProps} /> }
      { type === IconType.DOWN_OUTLINED && <DownOutlined {...iconProps} /> }
      { type === IconType.LEFT_OUTLINED && <LeftOutlined {...iconProps} /> }
      { type === IconType.RIGHT_OUTLINED && <RightOutlined {...iconProps} /> }
      { type === IconType.CLOSE_OUTLINED && <CloseOutlined {...iconProps} /> }
      { type === IconType.QUESTION_CIRCLE_OUTLINED && <QuestionCircleOutlined {...iconProps} /> }
      { type === IconType.SELECTED_OUTLINED && <SelectedOutlined {...iconProps} /> }
      { type === IconType.UN_SELECTED_OUTLINED && <UnSelectedOutlined {...iconProps} /> }
      { type === IconType.MINUS_OUTLINED && <MinusOutlined {...iconProps} /> }
      { type === IconType.MINUS_CIRCLE_OUTLINED && <MinusCircleOutlined {...iconProps} /> }
      { type === IconType.PLUS_OUTLINED && <PlusOutlined {...iconProps} /> }
      { type === IconType.PLUS_CIRCLE_OUTLINED && <PlusCircleOutlined {...iconProps} /> }
      { type === IconType.TIME_OUTLINED && <TimeOutlined {...iconProps} /> }
      { type === IconType.STAR_OUTLINED && <StarOutlined {...iconProps} /> }
      { type === IconType.STORE_OUTLINED && <StoreOutlined {...iconProps} /> }
      { type === IconType.RECYCLE_BIN_OUTLINED && <RecycleBinOutlined {...iconProps} /> }
      { type === IconType.CHECKED_OUTLINED && <CheckedOutlined {...iconProps} /> }
      { type === IconType.SEARCH_OUTLINED && <SearchOutlined {...iconProps} /> }
      { type === IconType.EMAIL_OUTLINED && <EmailOutlined {...iconProps} />}
      { type === IconType.PHONE_OUTLINED && <PhoneOutlined {...iconProps} />}
      { type === IconType.EYE_OPEN_OUTLINED && <EyeOpenOutlined {...iconProps} />}
      { type === IconType.EYE_CLOSED_OUTLINED && <EyeClosedOutlined {...iconProps} />}
      { type === IconType.PICK_UP_OUTLINED && <PickUpOutlined {...iconProps} />}
      { type === IconType.DELIVERY_OUTLINED && <DeliveryOutlined {...iconProps} />}
      { type === IconType.BOOK_OUTLINED && <BookOutlined {...iconProps} />}
      { type === IconType.SETTING_OUTLINED && <SettingOutlined {...iconProps} />}
      { type === IconType.ADDRESS_OUTLINED && <AddressOutlined {...iconProps} />}
      { type === IconType.DEFAULT_AVATAR_OUTLINED && <DefaultAvatarOutlined {...iconProps} />}
      { type === IconType.EDIT_OUTLINED && <EditOutlined {...iconProps} />}
      { type === IconType.CAMERA_OUTLINED && <CameraOutlined {...iconProps} />}
      { type === IconType.TIME_SOLID_OUTLINED && <TimeSolidOutlined {...iconProps} />}
      { type === IconType.NAV_ACCOUNT_OUTLINED && <NavAccountOutlined {...iconProps} />}
      { type === IconType.NAV_ORDERS_OUTLINED && <NavOrdersOutlined {...iconProps} />}
      { type === IconType.MAP_OUTLINED && <MapOutlined {...iconProps} /> }
      { type === IconType.SHOPPING_CART_OUTLINED && <ShoppingCartOutlined {...iconProps} />}
      { type === IconType.GUEST_OUTLINED && <GuestOutlined {...iconProps} />}
      { type === IconType.ALREADY_FAVORITE_OUTLINED && <AlreadyFavoriteOutlined {...iconProps} />}
      { type === IconType.NOT_FAVORITE_OUTLINED && <NotFavoriteOutlined {...iconProps} />}
      { type === IconType.SCAN_OUTLINED && <ScanOutlined {...iconProps} />}
      { type === IconType.BOOKING_OUTLINED && <BookingOutlined {...iconProps} />}
      { type === IconType.QUEUEING_OUTLINED && <QueueingOutlined {...iconProps} />}
      { type === IconType.FACEBOOK_OUTLINED && <FacebookOutlined {...iconProps} />}
      { type === IconType.APPLE_OUTLINED && <AppleOutlined {...iconProps} />}
      { type === IconType.WECHAT_OUTLINED && <WechatOutlined {...iconProps} />}
      { type === IconType.TEL_PHONE_OUTLINED && <TelPhoneOutlined {...iconProps} />}
      { type === IconType.LOADING && <Loading {...iconProps} />}
      { type === IconType.NEWS_OUTLINED && <NewsOutlined {...iconProps} />}
      { type === IconType.ABOUT_US_OUTLINED && <AboutUsOutlined {...iconProps} />}
      { type === IconType.LOCATION_OUTLINED && <LocationOutlined {...iconProps} />}
      { type === IconType.QUICK_OUTLINED && <QuickOutlined {...iconProps} />}
      { type === IconType.VOUCHER_OUTLINED && <VoucherOutlined {...iconProps} />}
      { type === IconType.BOOKING_SETTING && <BookingSetting {...props} />}
      { type === IconType.QUEUEING_SETTING && <QueueingSetting {...props} />}
      { type === IconType.FAVORITE_SETTING && <FavoriteSetting {...props} />}
      { type === IconType.PAYMENT_SETTING && <PaymentSetting {...props} />}
      { type === IconType.BINDO_SETTING && <BindoSetting {...props} />}
      { type === IconType.BECOME_PARTNER_SETTING && <BecomePartnerSetting {...props} />}
      { type === IconType.HOME_OUTLINED && <HomeOutlined {...props} />}
      { type === IconType.PROMOTIONS_OUTLINED && <PromotionsOutlined {...props} />}
      { type === IconType.MEMBERS_OUTLINED && <MembersOutlined {...props} />}
      { type === IconType.RECORDS_OUTLINED && <RecordsOutlined {...props} />}
      { type === IconType.SHOPPING_OUTLINED && <ShoppingOutlined {...props} />}
      { type === IconType.STAFF_ID_OUTLINED && <StaffIDOutlined {...props} />}
      { type === IconType.QR_CODE_OUTLINED && <QRCodeOutlined {...props} />}
      { type === IconType.MEMBER_CARD_OUTLINED && <MemberCardOutlined {...props} />}
      { type === IconType.EDIT_ADDRESS_OUTLINED && <EditAddressOutlined {...props} />}
      { type === IconType.MY_ID_OUTLINED && <MyIDOutlined {...props} />}
      { type === IconType.MEMBER_OUTLINED && <MemberOutlined {...props} />}
      { type === IconType.MEMBER_TAG_OUTLINED && <MemberTagOutlined {...props} />}
      { type === IconType.MENUS_OUTLINED && <MenusOutlined {...props} />}
      { type === IconType.POSITION_OUTLINED && <PositionOutlined {...props} />}
      { type === IconType.GOOGLE_LOCALTION_OUTLINED && <GoogleLocaltionOutlined {...props} />}
      { type === IconType.FOOD_ORDERING_OUTLINED && <FoodOrderingOutlined {...props} />}
      { type === IconType.REFRESH_OUTLINED && <RefreshOutlined {...props} />}
      { type === IconType.SHARE_OUTLINED && <ShareOutlined {...props} />}
      { type === IconType.DATE_OUTLINED && <DateOntlined {...props} />}
      { type === IconType.BOOKING_SETTING_BRAND && <BookingSettingBrand {...props} />}
      { type === IconType.QUEUEING_SETTING_BRAND && <QueueingSettingBrand {...props} />}
      { type === IconType.COMING_SOON_OUTLINED && <ComingSoonOutlined {...props} />}
      { type === IconType.DELIVEROO_OUTLINED && <DeliverooOutlined {...props} />}
      { type === IconType.FOODPANDA_OUTLINED && <FoodpandaOutlined {...props} />}
      { type === IconType.STORE_URL_OUTLINED && <StoreUrlOutlined {...props} />}
      { type === IconType.CATEGORY_OUTLINED && <CategoryOutlined {...props} />}
      { type === IconType.SCREEN_OUTLINED && <ScreenOutlined {...props} />}
      { type === IconType.STATUS_ERROR_OUTLINED && <StatusErrorOutlined {...props} />}
      { type === IconType.LEFT_ARROW_OUTLINED && <LeftArrowOutlined {...props} /> }
      { type === IconType.EXPRSESS_ORDER_OUTLINED && <ExpressOrderOutlined {...props} /> }
      { type === IconType.WHATS_APP_OUTLINED && <WhatsAppOutlined {...props} /> }
      { type === IconType.APPLE_EMAIL_OUTLINED && <AppleEmailOutlined {...props} /> }
      { type === IconType.GEMAIL_OUTLINED && <GEmailOutlined {...props} /> }
      { type === IconType.COPY_OUTLINED && <CopyOutlined {...iconProps} /> }
      { type === IconType.PRIVILEGE_OUTLINED && <PrivilegeOutlined {...iconProps} /> }
      { type === IconType.UN_SELECTED_SQUARE_OUTLINED && <UnSelectedSquareOutlined {...iconProps} /> }
      { type === IconType.CHECKED_SQUARE_OUTLINED && <CheckedSquareOutlined {...iconProps} /> }
      { type === IconType.ALIPAY_HK && <AlipayHK {...iconProps} /> }
      { type === IconType.NAV_LOGO && <NavLogo {...iconProps} /> }
      { type === IconType.SHOPPING_CART && <ShoppingCart {...iconProps} /> }
      { type === IconType.DEFERRED_INVOICE_OUTLINED && <DeferredInvoiceOutlined {...iconProps} /> }
      { type === IconType.JUST_LOGO && <JustLogo {...iconProps} /> }
      { type === IconType.FACEBOOK && <Facebook {...iconProps} /> }
      { type === IconType.TWITTER && <Twitter {...iconProps} /> }
      { type === IconType.INSTAGRAM && <Instagram {...iconProps} /> }
      { type === IconType.LOGOUT_OUTLINED && <LogoutOutlined {...iconProps} /> }
      { type === IconType.SETTING2_OUTLINED && <Setting2Outlined {...iconProps} /> }
      { type === IconType.DOLLAR_OUTLINED && <DollarOutlined {...iconProps} /> }
      { type === IconType.PAYMENT_OUTLINED && <PaymentOutlined {...iconProps} /> }
      { type === IconType.RECORD_OUTLINED && <RecordOutlined {...iconProps} /> }
      { type === IconType.GRN_OUTLINED && <GRNOutlined {...iconProps} /> }
      { type === IconType.RN_OUTLINED && <RNOutlined {...iconProps} /> }
    </>
  );
}

export default Icon;
